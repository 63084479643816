import './styles/main.scss';
import {BrowserRouter, Route, Routes, useSearchParams} from 'react-router-dom';
import {useMemo, useState} from "react";
import InstallPage from "./pages/InstallPage";
import MainPage from "./pages/MainPage";
import Loading from "./elements/Loading";

function App() {
    const [appInitialized, setAppInitialized] = useState(false)

    const BX24 = useMemo(() => window.BX24, [])
    BX24.init(() => setAppInitialized(true));

    if (!appInitialized) {
        return <Loading isLoading={true}/>
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<MainPage
                    appInitialized={appInitialized}
                    setAppInitialized={setAppInitialized}
                    BX24={BX24}
                />} />
                <Route exact path="/install" element={
                    <InstallPage
                        appInitialized={appInitialized}
                        setAppInitialized={setAppInitialized}
                        BX24={BX24}
                    />
                } />
            </Routes>
        </BrowserRouter>
    )

}

export default App;
