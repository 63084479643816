import '../styles/pages/setup.scss'
import {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";
import getAPIClient from "../hooks/apiClient";
import Loading from "../elements/Loading";
import Header from "../elements/Header";
import DefaultInput from "../elements/inputs/DefaultInput";
import SubmitButton from "../elements/inputs/SubmitButton";
import BitrixUserSelector from "../elements/inputs/BitrixUserSelector";
import InlineNotification from "../elements/InlineNotification";

export default function InstallPage({
                                        appInitialized,
                                        setAppInitialized,
                                        BX24
                                    }) {
    const [isLoading, setIsLoading] = useState(false)

    const apiClient = getAPIClient(BX24)

    const [botToken, setBotToken] = useState('')
    const [selectedManager, setSelectedManager] = useState(undefined)

    const makeRandomString = useCallback((length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }, [])

    const checkReinstall = useCallback(async () => {
        setIsLoading(true)
        const data = {
            fingerprint: makeRandomString(30)
        }
        try {
            const response = await apiClient.post('/resetup', data)
            BX24.installFinish()
        } catch (e) {
            if (e.response?.status !== 403) {
                setNotificationMessage('Произошла ошибка при переустановке приложения. Пожалуйста, обратитесь к администратору')
            }
            setIsLoading(false)
        }
    }, [apiClient, BX24])


    const [reinstallChecked, setReinstallChecked] = useState(false)
    useEffect(() => {
        if (BX24.getAllParams().AUTH_ID === '') {
            return
        }

        if (!reinstallChecked) {
            checkReinstall()
            setReinstallChecked(true)
        }

    }, [checkReinstall])

    const installApplication = useCallback(async() => {
        setIsLoading(true)
        setNotificationType('error')
        setNotificationMessage('')
        const data = {
            install_data: {
                fingerprint: makeRandomString(30)
            },
            setup_in: {
                menu_title: 'HelpRobot',
                client_bot_api_token: botToken,
                main_user_id: `${selectedManager.id}`
            }
        }
        try {
            const response = await apiClient.post('/setup', data)
            BX24.installFinish()
        } catch (e) {
            setNotificationMessage('Произошла какая-то ошибка. Посмотрите информацию о ней в запросе')
            setIsLoading(false)
        }
    }, [botToken, selectedManager, apiClient])

    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState('info')

    return <>
        <Loading isLoading={(!appInitialized) || isLoading}/>
        <Header/>
        <div className={'center-main'}>
            <div className='setup-block'>
                <InlineNotification
                    type={notificationType}
                    text={notificationMessage}
                    hideFlag={notificationMessage.length === 0}
                />
                <DefaultInput
                    title={'Токен телеграм бота'}
                    onChangeFunction={(el) => setBotToken(el.target.value)}
                    value={botToken}
                />
                <BitrixUserSelector
                    title={'Выберите ответственного за новые заявки через эту систему'}
                    selectedUser={selectedManager}
                    setSelectedUser={setSelectedManager}
                    BX24={BX24}
                />
                <SubmitButton
                    onClick={installApplication}
                    text={'Установить'}
                    disabled={botToken === '' || selectedManager === undefined}
                />
            </div>
        </div>
    </>
}
