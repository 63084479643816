import '../../styles/elements/inputs/bitrix-user-selector.scss'

export default function BitrixUserSelector(
    {className = "", title, selectedUser, setSelectedUser, BX24}
) {

    return (
        <div
            className={'user-selector' + className}
            onClick={() => BX24.selectUser(setSelectedUser)}
        >
            <p className={'user-selector-title'}>{title}</p>
            <div className={'user-selector-main'}>
                <h1>{selectedUser ? selectedUser.name : 'Пользователь не выбран'}</h1>
                <p>{selectedUser ? 'ID: ' + selectedUser.id : ''}</p>
            </div>
        </div>
    )
}
