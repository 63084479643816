import s from '../styles/pages/main.module.scss'
import DefaultInput from "../elements/inputs/DefaultInput";
import BitrixUserSelector from "../elements/inputs/BitrixUserSelector";
import SubmitButton from "../elements/inputs/SubmitButton";
import InlineNotification from "../elements/InlineNotification";
import Header from "../elements/Header";
import {useCallback, useEffect, useMemo, useState} from "react";
import Loading from "../elements/Loading";
import getAPIClient from "../hooks/apiClient";

export default function MainPage({
                                     appInitialized,
                                     setAppInitialized,
                                     BX24
                                 }) {

    const apiClient = useMemo(() => getAPIClient(BX24), [appInitialized, BX24])


    const [dataLoadedFlag, setDataLoadedFlag] = useState(false)
    const [botToken, setBotToken] = useState('')
    const [selectedManager, setSelectedManager] = useState()


    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState('error')

    const [dataChangedFlag, setDataChangedFlag] = useState(false)

    const updateSettings = useCallback(async () => {
        setDataLoadedFlag(false)
        setDataChangedFlag(false)
        setNotificationMessage('')
        try {
            const response = await apiClient.put('/settings', {
                client_bot_api_token: botToken,
                main_user_id: selectedManager?.id
            })
        } catch (e) {
            setNotificationType('error')
            setNotificationMessage(
                'Произошла ошибка. Проверьте правильность заполнения полей или напишите в тех. поддержку'
            )
        }
        setDataLoadedFlag(true)
    }, [botToken, selectedManager, apiClient])

    const loadSettings = useCallback(async () => {
        const response = await apiClient.get('/settings')
        setBotToken(response.data.client_bot_api_token)
        BX24.callMethod('user.get', {'ID': response.data.main_user_id}, (r) => {
            const selectedManagerName = r.answer.result[0].NAME + r.answer.result[0].LAST_NAME
            setSelectedManager({
                'name': selectedManagerName.length !== 0 ? selectedManagerName : r.answer.result[0].EMAIL,
                'id': r.answer.result[0].ID
            })
            setDataLoadedFlag(true)
        })
    }, [BX24, apiClient])

    useEffect(() => {
        if (appInitialized) {
            loadSettings()
        }
    }, [apiClient])


    const [globalNotificationMessage, setGlobalNotificationMessage] = useState('')
    const [globalNotificationType, setGlobalNotificationType] = useState('error')


    return (
        <>
            <Loading isLoading={!appInitialized || !dataLoadedFlag}/>
            <Header/>
            <div className={s.main}>
                <InlineNotification
                    type={globalNotificationType}
                    text={globalNotificationMessage}
                    hideFlag={globalNotificationMessage.length === 0}
                />
                <div className={s.main_container}>
                    <div className={s.settings}>
                        <DefaultInput
                            title={'Токен телеграм бота'}
                            onChangeFunction={(el) => {
                                setBotToken(el.target.value)
                                setDataChangedFlag(true)
                            }}
                            value={botToken}
                        />
                        <BitrixUserSelector
                            title={'Ответственный за новые тикеты'}
                            setSelectedUser={(el) => {
                                setSelectedManager(el)
                                setDataChangedFlag(true)
                            }}
                            selectedUser={selectedManager}
                            BX24={BX24}
                        />
                        <SubmitButton
                            onClick={updateSettings}
                            disabled={!dataChangedFlag}
                            className={s.submit}
                            text={'Сохранить'}
                        />
                        <InlineNotification
                            type={notificationType}
                            text={notificationMessage}
                            hideFlag={notificationMessage.length === 0}
                        />
                    </div>
                    <div className={s.lessons}>
                        <div className={s.lesson}>
                            <iframe
                                src="https://www.youtube.com/embed/ZWgcG5WjwXE"
                                title="Siberia 4K - Scenic Relaxation Film With Calming Music" frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                        <button className={s.all_lessons_button} onClick={() => window.open(
                            'https://www.youtube.com/playlist?list=PLQReu4zUp5f3kok-P89EXXjeb-xSrXkqx', '_blank')}>
                            Все обучающие материалы
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}